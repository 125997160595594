import React from 'react'
import '../styles/popup.css'

export const LoadingPopup = () => {
 
      return (
        <div className='loading-popup'>
          <div className='loading-content'>
            <p>Loading Data Adding...</p>
          </div>
        </div>
      );
  
}
